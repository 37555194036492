import { ApplicationConfig, importProvidersFrom, isDevMode } from '@angular/core';
import { Routes, provideRouter, withComponentInputBinding, withDisabledInitialNavigation, withEnabledBlockingInitialNavigation, withRouterConfig } from '@angular/router';

import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideServiceWorker } from '@angular/service-worker';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptorConfiguration, MsalModule, MsalService } from '@azure/msal-angular';
import { BrowserCacheLocation, BrowserUtils, IPublicClientApplication, InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { provideNativeDateAdapter } from '@angular/material/core';

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

// export async function MSALInstanceFactory(): Promise <IPublicClientApplication> {
export function MSALInstanceFactory(): IPublicClientApplication {

  var authCfg = (window as any)['clientCfg'];

  // build application
  const clientApp = new PublicClientApplication({
    auth: {
      clientId: authCfg.audience, // Application (client) ID from the app registration
      authority: `${authCfg.authority}/${authCfg.domain}/${authCfg.signUpSignInPolicyExt}`, // The Azure cloud instance and the app's sign-in audience (tenant ID, common, organizations, or consumers)
      knownAuthorities: authCfg.knownAuthorities,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Warning,
        piiLoggingEnabled: false
      }
    }
  });

  return clientApp;
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {

  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };

}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    //authRequest: {
    //  scopes: [...environment.apiConfig.scopes]
    //},
    loginFailedRoute: '/home'
  };
}

const initialNavigation = !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
  ? withEnabledBlockingInitialNavigation() // Set to enabledBlocking to use Angular Universal
  : withDisabledInitialNavigation(); 


export function appConfig(routes: Routes): ApplicationConfig {
  return {

      providers: [
        importProvidersFrom(BrowserModule, MsalModule, HammerModule, MatToolbarModule, MatIconModule, MatBadgeModule, MatButtonModule),
        provideRouter(routes, initialNavigation, withComponentInputBinding(), withRouterConfig({ paramsInheritanceStrategy: 'always' })),
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi()),
        provideServiceWorker('ngsw-worker.js', {
            enabled: !isDevMode(),
            registrationStrategy: 'registerWhenStable:30000'
        }),
        provideNativeDateAdapter(),
        {
          provide: MSAL_INSTANCE,
          useFactory: MSALInstanceFactory
        },
        {
          provide: MSAL_GUARD_CONFIG,
          useFactory: MSALGuardConfigFactory
        },
          //{
          //  provide: MSAL_INTERCEPTOR_CONFIG,
          //  useFactory: MSALInterceptorConfigFactory
          //},
          //{
          //  provide: HTTP_INTERCEPTORS,
          //  useClass: MsalInterceptor,
          //  multi: true
          //},
          MsalService,
          MsalGuard,
          MsalBroadcastService,
    ]
  }
};
