import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MeService, ViewSidenavComponent } from 'dist/ad4core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  standalone: true,
  imports: [ViewSidenavComponent]
})
export class AppComponent {

  public isIframe: boolean = false;
  public get initialized(): boolean {
    return !this.isIframe && (this.me.session ? true : false);
  }

  constructor(
    private me: MeService, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer
  ) {

    // set application version
    this.me.setApplication("ad4wine", "3.1");

    // add social icons
    iconRegistry.addSvgIcon('facebook', sanitizer.bypassSecurityTrustResourceUrl('/assets/social/facebook.svg'));
    iconRegistry.addSvgIcon('facebook-messenger', sanitizer.bypassSecurityTrustResourceUrl('/assets/social/facebook-messenger.svg'));
    iconRegistry.addSvgIcon('instagram', sanitizer.bypassSecurityTrustResourceUrl('/assets/social/instagram.svg'));
    iconRegistry.addSvgIcon('linkedin', sanitizer.bypassSecurityTrustResourceUrl('/assets/social/linkedin.svg'));
    iconRegistry.addSvgIcon('pinterest', sanitizer.bypassSecurityTrustResourceUrl('/assets/social/pinterest.svg'));
    iconRegistry.addSvgIcon('tiktok', sanitizer.bypassSecurityTrustResourceUrl('/assets/social/tiktok.svg'));
    iconRegistry.addSvgIcon('twitter', sanitizer.bypassSecurityTrustResourceUrl('/assets/social/twitter.svg'));
    iconRegistry.addSvgIcon('twitterx', sanitizer.bypassSecurityTrustResourceUrl('/assets/social/twitterx.svg'));
    iconRegistry.addSvgIcon('whatsapp', sanitizer.bypassSecurityTrustResourceUrl('/assets/social/whatsapp.svg'));
    iconRegistry.addSvgIcon('youtube', sanitizer.bypassSecurityTrustResourceUrl('/assets/social/youtube.svg'));

  }

  async ngOnInit(): Promise<void> {

    // if we are running within iFrame, do not show any UI - MSAL acquireTokenSilent
    this.isIframe = window !== window.parent && !window.opener;

    // me service initialization
    await this.me.OnAppInit();

  }

  ngOnDestroy(): void {

    this.me.OnAppDestroy();

  }


}
