
// localization
import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeDe from '@angular/common/locales/de';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localeCs from '@angular/common/locales/cs';
import localeSk from '@angular/common/locales/sk';
import localePl from '@angular/common/locales/pl';


// register locales
export function registerLocales() {

  registerLocaleData(localeEn);
  registerLocaleData(localeDe);
  registerLocaleData(localeEs);
  registerLocaleData(localeFr);
  registerLocaleData(localeIt);
  registerLocaleData(localeCs);
  registerLocaleData(localeSk);
  registerLocaleData(localePl);

}

