import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Route, RouterStateSnapshot, UrlMatchResult, UrlSegment, UrlSegmentGroup } from '@angular/router';
import { MeService, NavService } from 'dist/ad4core';

// dynamic route initialization
// https://stackoverflow.com/questions/59236582/angular-load-routing-from-rest-service-before-initialization/61694578#61694578

// optional parameters 'doc/:docId'
// https://stackoverflow.com/questions/34208745/angular-2-optional-route-parameter

// / in parameter value
// https://stackoverflow.com/questions/44810860/forward-slash-in-angular-route-parameters

export function publicPathMatcher(segments: UrlSegment[], group: UrlSegmentGroup, route: Route): UrlMatchResult {
  return {
    consumed: [],
    posParams: {
      areaId: new UrlSegment("public", {})
    }
  };
}

export function searchPathMatcher(segments: UrlSegment[], group: UrlSegmentGroup, route: Route): UrlMatchResult {
  // match urls like "portal/:docId" where docId can contain '/'
  if (segments.length > 0) {
    // if first segment is 'search', then concat all the next segments into a single one
    // and return it as a parameter named 'docId'
    if (segments[0].path == "search") {
      return {
        consumed: segments,
        posParams: {
          path: new UrlSegment(segments.slice(1).join("/"), {})
        }
      };
    }
  }
  return null;
}

export function waitForSession(intervalMs: number = 250, timeoutMs: number = 5000): Promise<boolean> {

  const me = inject(MeService);
  const nav = inject(NavService);

  return new Promise((resolve, reject) => {
    const startTime = Date.now();
    const interval = setInterval(() => {

      if (me.session) {
        clearInterval(interval);
        resolve(me.isAdmin);
      }
      else if (Date.now() - startTime > timeoutMs) {
        clearInterval(interval);
        nav.to('home');
        resolve(false);
      }

    }, intervalMs);

  });
}

export const canActivateAdmin: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const me = inject(MeService);
  const nav = inject(NavService);
  if (me.session)
    return me.isAdmin;

  nav.to('home');
  return false;
}

