import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { appConfig } from '@app/config';
import { registerLocales } from '@app/locales';
import { routes } from './app/app.routes';

// register locales
registerLocales();

bootstrapApplication(AppComponent, appConfig(routes))
  .catch((err) => console.error(err));
