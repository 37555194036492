import { Routes } from '@angular/router';
import { canActivateAdmin, publicPathMatcher } from '@app/routes';
import { UiDummyComponent, ViewAssistComponent, ViewEntityDetailComponent, ViewEntityListComponent, ViewPageComponent, ViewUserAccountComponent, ViewUserCartComponent, ViewUserComponent, ViewUserDocumentsComponent, ViewUserNotesComponent, ViewUserOrderComponent, ViewUserOrdersComponent, ViewEventsComponent, ViewEventComponent } from 'dist/ad4core';

export const routes: Routes = [
  { path: 'admin', canActivate: [canActivateAdmin], loadChildren: () => import('@app/admin/module').then(m => m.AdminModule) },
  { path: 'reload', component: UiDummyComponent },
  {
    matcher: publicPathMatcher, children: [
      { path: 'products/:collectionId/:entityId', component: ViewEntityDetailComponent },
      { path: 'products/:collectionId', component: ViewEntityListComponent },
      { path: 'services/events/:eventId', component: ViewEventComponent },
      { path: 'services/events', component: ViewEventsComponent },
      { path: 'me/account', component: ViewUserAccountComponent },
      { path: 'me/cart', component: ViewUserCartComponent },
      { path: 'me/documents', component: ViewUserDocumentsComponent },
      { path: 'me/notes', component: ViewUserNotesComponent },
      { path: 'me/orders/:transactionId', component: ViewUserOrderComponent },
      { path: 'me/orders', component: ViewUserOrdersComponent },
      { path: 'me', component: ViewUserComponent },
      { path: 'assist', component: ViewAssistComponent },
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: '**', component: ViewPageComponent },
    ]
  }
];
